.headerMain {
  background: linear-gradient(45deg, #000850 0%, #000320 100%),
    radial-gradient(100% 225% at 100% 0%, #f000d3 0%, #000000 100%),
    linear-gradient(225deg, #f000d3 0%, #000000 100%),
    linear-gradient(
      135deg,
      #aff996 10%,
      #aff996 35%,
      #2cf5d3 35%,
      #2cf5d3 60%,
      #5100c9 60%,
      #5100c9 67%,
      #300177 67%,
      #300177 100%
    );
  background-blend-mode: screen, overlay, hard-light, normal;
  background-size: cover;
  height: 90vh;
  padding-top: 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 50px;
  padding-right: 50px;
  gap: 10px;
  padding-bottom: 75px;
}

.headerOpening {
  color: #fff;
}

.vrImg {
    width: 100%;
    z-index: -50;
  }
  

@media only screen and (max-width: 720px) {
    .headerMain {
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      padding-top: 300px;
      align-content: center;
    }

    .vrImg {
        width: 100%;
        z-index: -50;
        margin-top: 15px;
    }

    .headerOpening {
        align-items: center;
    }
  }
  

.mainContainer {
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.05));
}

.bgVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.headerContainer {
  color: #fff;
  width: 95%;
  height: 100px;
  border-radius: 20px;
  text-align: center;
}

.headerTitle {
  font-size: 3.7rem;
  margin-top: 0px;
  background: -webkit-radial-gradient(
    circle,
    #F000D3,
    #5100C9 7%,
    rgba(6, 84, 170, 1) 51%,
    rgba(0, 255, 113, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footerContainer {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  margin-bottom: -20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.footerLogo {
  width: 75px;
}

.footerNavBarContainer {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.mainContainer {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.socialMediaContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.navLink {
  color: #fff;
  transition: 0.5s;
  padding: 10px;
  border-radius: 25px;
  text-decoration: none;
}

.navLink:hover {
  background-color: #b9f318;
  color: #000;
}

.VContainerNavLink {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formLabel {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

form {
  display: flex;
  flex-direction: row;
  border: 1px solid #b9f418;
  padding: 1px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 40px;
}

input {
  flex-grow: 2;
  border: none;
  background-color: rgba(10, 10, 10, 0.6);
  backdrop-filter: blur(20px);
  color: #fff;
  width: 250px;
  height: 30px;
  padding-left: 10px;
}

input:focus {
  outline: none;
}

form:focus-within {
  outline: 1px solid #b9f418;
}

button {
  margin: -2px;
  border: 1px solid #b9f418;
  background: #b9f418;
  color: black;
}

@media only screen and (max-width: 720px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footerNavBarContainer {
    flex-direction: column;
  }

  .mainContainer {
    flex-direction: column;
  }

  .copyrightContainer {
    margin-top: -10px;
  }

  form {
    margin-left: 0px;
  }

  .formLabel {
    margin-top: 20px;
  }

  .copyrightContainer {
    margin-top: 30px;
  }
}

:root {
  --green: #60F42D;
  --magenta: #F000D3;
  --cyan: #2CF5D3;
  --purple: #5100C9;
}

.mainContainer {
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2));
}

.bgVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.headerContainer {
  color: #fff;
  width: 95%;
  height: 100px;
  border-radius: 20px;
  text-align: center;
}

.headerTitle {
  font-size: 3.7rem;
  margin-top: 0px;
  background: linear-gradient(90deg, var(--purple), var(--purple), var(--magenta), var(--purple), var(--purple));
  /* -webkit-radial-gradient(circle,
      #F000D3 10%,
      #e800f0 20%,
      #5100C9 40%,
      rgba(6, 84, 170, 1) 90%,
      rgba(0, 255, 113, 1) 100%); */
      background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.openingLine {
  color: #fff;
  font-size: 1.5rem;
  width: 80%;
  text-align: center;
}

.openingLineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}

.vrimageContainer {
  background-color: #fff;
  height: 200px;
}

.videoSideBarContainer {
  background: -webkit-radial-gradient(right 60%, #2cf5d3, #00074D, #00074D);
  padding-top: 70px;
  padding-bottom: 70px;
}

.starBackground {
  background-image: radial-gradient(
      #60f42d,
      rgba(96, 244, 45, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(#f000d3, rgba(240, 0, 211, 0.2) 1px, transparent 30px),
    radial-gradient(#2cf5d3, rgba(44, 245, 211, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
  height: 100vh;
}

.mobileStart {
  background-color: #00074D;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.linkedinContainer {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 720px) {
  .linkedinContainer {
    flex-direction: column;
    gap: 10px;
  }
}

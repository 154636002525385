.cardsContainer {
    background-image: linear-gradient(185deg, #00074D, #00074D, #f000d4, #00074D, #00074D);
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    gap: 10px;
    padding-bottom: 75px;
    display: flex;
    flex-direction: row;
    color: white;
  }
  
  .glassCard {
    backdrop-filter: blur(40px);
    background-color: rgba(200, 200, 200, 0.09);
    border-radius: 20px;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  
  .glassCardText {
    max-width: 35ch;
  }

  .glassCardUL {
    margin-bottom: 60px;
  }
  
  @media only screen and (max-width: 720px) {
    .cardsContainer {
      flex-direction: column;
      justify-content: center;
    }
  }
  
:root {
    --green: #60F42D;
    --magenta: #F000D3;
    --cyan: #2CF5D3;
    --purple: #5100C9;
}

body {
    overflow-x: hidden;
}

.main1 {
    background: linear-gradient(45deg, #2CF5D3 0%, #000320 100%),
        radial-gradient(100% 225% at 100% 0%, #F000D3 0%, #000000 100%),
        linear-gradient(225deg, #F000D3 0%, #000000 100%),
        linear-gradient(135deg,
            #aff996 10%,
            #aff996 35%,
            #2CF5D3 35%,
            #2CF5D3 60%,
            #5100C9 60%,
            #5100C9 67%,
            #300177 67%,
            #300177 100%);
    background-blend-mode: screen, overlay, hard-light, normal;
    background-size: cover;
    height: 100vh;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 50px;
    padding-right: 50px;
    gap: 10px;
    padding-bottom: 75px;
}

.spotify {
    border-radius: 12px;
}

.experimentalLearningContainer {
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    margin-left: 40px;
}

.experimentalLearningTextCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-right: 30px;
}

.experimentalLearningImagesCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 30px;
    margin-top: 100px;
}

.kolb {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    color: white;
    background: rgba(255, 255, 255, 0.148);
    padding: 10px;
    border-radius: 25px;
    text-align: center;
}

.SageScience1 {
    margin-top: 30vh;
    width: 100%;
    height:70vh;
    border-radius: 10%;
    opacity: 80%;
    margin-bottom: 0px;
}

.experimentalLearningHeaderDiv {
    width: 100%;
    background:rgba(255, 255, 255, 0.0);
    height:30vh;
    border-radius: 25px;
    margin-top: 120px;
    margin-bottom: 50px;
    padding-bottom: 20px;
    justify-content:center;
}

.experimentalLearningHeader {
    font-size: 3.3rem;
    font-weight: 900;
    text-align: center;
    background: linear-gradient(330deg,var(--cyan) 40%, var(--green) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 25px;
}

.VRContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(80deg, var(--cyan) 5%, #4476AA 95%);
    background-blend-mode: overlay, screen, overlay, hard-light, overlay, normal;
}

.VRHeader {
    color: white;
    font-size: xxx-large;
    font-weight: 900;
    background: rgba(255, 255, 255, 0.148);
    background: linear-gradient(330deg, var(--magenta) 40%, var(--purple) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 25px;
    padding: 20px;
    margin: 20px;
    margin-top:50px
}

.VRBody {
    color: white;
    text-align: center;
    background: rgba(255, 255, 255, 0.148);
    border-radius: 25px;
    padding: 10px;
    margin: 40px;
    margin-left: 80px;
    margin-right: 80px;
    font-size: large;
}

.marginGone {
    margin: 0px;
}

.personalization {
    background: linear-gradient(45deg, #2CF5D3 0%, #000320 100%),
        radial-gradient(100% 225% at 100% 0%, #F000D3 0%, #000000 100%),
        linear-gradient(225deg, #F000D3 0%, #000000 100%),
        linear-gradient(135deg,
            #aff996 10%,
            #aff996 35%,
            #2CF5D3 35%,
            #2CF5D3 60%,
            #5100C9 60%,
            #5100C9 67%,
            #300177 67%,
            #300177 100%);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";

    background-blend-mode: screen, overlay, hard-light, normal;
    background-size: cover;
    height: 150vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 50px;
    padding-right: 50px;
    gap: 10px;
}


.personalizationContainer {
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    margin-left: 40px;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}

.personalizationTextCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-left: 30px;
}

.personalizationImagesCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 30px;

}

.personalizationBody {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    color: white;
    background: rgba(255, 255, 255, 0.148);
    padding: 10px;
    border-radius: 25px;
    text-align: center;
    font-size: large;
    font-size:1.5rem;
}

.SageScience3 {
    margin-top: 55px;
    width: 100%;
    border-radius: 10%;
    opacity: 80%;
    margin-bottom: 50px;
}

.SageScience4 {
    width: 100%;
    border-radius: 10%;
    opacity: 80%;
}

.personalizationHeaderDiv {
    width: 100%;
    margin-top: 40px;
}

.personalizationHeader {
    font-size: xxx-large;
    font-weight: 900;
    text-align: center;
    background: linear-gradient(330deg, var(--magenta) 40%, var(--cyan) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 25px;
    padding: 10px;
    margin-bottom: 150px;
}

.ongoingResearchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(80deg, #2C8F89, var(--magenta));
    background-blend-mode: screen, overlay;
    padding: 30px;
    padding-bottom: 100px;
}

.researchHeader {
    font-size: xxx-large;
    font-weight: 900;
    background: linear-gradient(330deg, var(--green) 40%, var(--magenta) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 10px;
}

.researchBody {
    font-size: large;
    font-weight: 300;
    color: white;
    text-align: center;
    padding: 10px;
}

.VRBold {
    background: linear-gradient(330deg, var(--magenta) 40%, var(--purple) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.kolbBold {
    font-weight:900;
    background: linear-gradient(330deg, var(--cyan) 40%, var(--green) 60%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer {
    background: linear-gradient(80deg, #2F8D8A, var(--magenta));
}

.VRu {
    text-decoration: underline;
}

@media only screen and (max-width: 720px) {
    .main1 {
        background: linear-gradient(45deg, #2CF5D3 0%, #000320 100%),
            radial-gradient(100% 225% at 100% 0%, #F000D3 0%, #000000 100%),
            linear-gradient(225deg, #F000D3 0%, #000000 100%),
            linear-gradient(135deg,
                #aff996 10%,
                #aff996 35%,
                #2CF5D3 35%,
                #2CF5D3 60%,
                #5100C9 60%,
                #5100C9 67%,
                #300177 67%,
                #300177 100%);
        background-blend-mode: screen, overlay, hard-light, normal;
        background-size: cover;
        height: 160vh;
        width:100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding:0px;
        gap: 10px;
        position:relative;
    }

    .experimentalLearningContainer {
            margin:0px;
            width: 90vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top:10%;
        }

    .experimentalLearningTextCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0px;
        
    }

    .experimentalLearningImagesCol {
        display: flex;
        flex-direction: column;
        margin:0;
    }

    .kolb {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        color: white;
        background: rgba(255, 255, 255, 0.148);
        padding: 10px;
        border-radius: 25px;
        text-align: center;
        width:80%;
    }

    .experimentalLearningHeaderDiv {
        margin: 0px;
        width: 100%;
        background: rgba(255, 255, 255, 0.0);
        border-radius: 25px;
        height:fit-content;
        justify-content: start;
        align-self: flex-start;
    }

    .experimentalLearningHeader {
        font-size: 1.4rem;
        font-weight: 900;
        text-align: center;
        background: linear-gradient(330deg, var(--cyan) 40%, var(--green) 60%);
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-radius: 25px;
    }

    .VRContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(80deg, var(--cyan) 5%, #4476AA 95%);
        background-blend-mode: overlay, screen, overlay, hard-light, overlay, normal;
    }
    
        .VRHeader {
            color: white;
            font-size: xxx-large;
            font-weight: 900;
            background: rgba(255, 255, 255, 0.148);
            background: linear-gradient(330deg, var(--magenta) 40%, var(--purple) 60%);
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-radius: 25px;
            padding: 20px;
            margin: 20px;
            margin-top: 50px
        }
    
        .VRBody {
            color: white;
            text-align: center;
            background: rgba(255, 255, 255, 0.148);
            border-radius: 25px;
            padding: 10px;
            margin: 40px;
            margin-left: 10vw;
            margin-right:10vw;
            font-size: large;
        }
    
        .marginGone {
            margin: 0px;
            text-align:center;
        }
    
        .personalization {
            background: linear-gradient(45deg, #2CF5D3 0%, #000320 100%),
                radial-gradient(100% 225% at 100% 0%, #F000D3 0%, #000000 100%),
                linear-gradient(225deg, #F000D3 0%, #000000 100%),
                linear-gradient(135deg,
                    #aff996 10%,
                    #aff996 35%,
                    #2CF5D3 35%,
                    #2CF5D3 60%,
                    #5100C9 60%,
                    #5100C9 67%,
                    #300177 67%,
                    #300177 100%);
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            filter: FlipV;
            -ms-filter: "FlipV";
            
            background-blend-mode: screen, overlay, hard-light, normal;
            background-size: cover;
            height: 170vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content:flex-end;
            padding:0;
            margin:0;
            gap: 10px;
        }
    
    
        .personalizationContainer {
            display: flex;
            flex-direction:column-reverse;
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            filter: FlipV;
            -ms-filter: "FlipV";
            width:100vw;
            margin:0;
            padding:0;
        }
    
        .personalizationTextCol {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            margin:0;
        }
    
        .personalizationImagesCol {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0;
        }
    
        .personalizationBody {
            display: flex;
            flex-direction: column;
            font-weight: 400;
            color: white;
            background: rgba(255, 255, 255, 0.148);
            padding: 10px;
            border-radius: 25px;
            text-align: center;
            font-size: large;
            margin-left:10vw;
            margin-right:10vw;
        }

        .SageScience1 {
            width: 80vw;
            height: 30vh;
            border-radius: 10%;
            opacity: 80%;
            margin-bottom: 100px;
            margin-top:10px;
            margin-right:0px;
            margin-left:0px;
        }

        .SageScience2 {
            opacity: 0%;
            width:0vw;
            height:0vh;
            margin:0px;
        }
    
        .SageScience3 {
            margin-top: 5vh;
            width: 80vw;
            border-radius: 10%;
            opacity: 80%;
            margin-bottom: 5vh;
            margin-right:0;
        }
    
        .SageScience4 {
            width: 80vw;
            border-radius: 10%;
            opacity: 80%;
        }
    
        .personalizationHeaderDiv {
            width: 100%;
            margin-top:0;
        }
    
        .personalizationHeader {
            font-size: 2.5rem;
            font-weight: 900;
            text-align: center;
            background: linear-gradient(330deg, var(--magenta) 40%, var(--cyan) 60%);
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-radius: 25px;
            padding: 10px;
            margin-bottom: 10vh;
        }
    
        .ongoingResearchContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(50deg, #2C8F89, var(--magenta));
            background-blend-mode: screen, overlay;
            padding: 30px;
        }
    
        .researchHeader {
            font-size: xxx-large;
            font-weight: 900;
            background: linear-gradient(330deg, var(--green) 40%, var(--magenta) 60%);
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align:center;
            padding: 10px;
        }
    
        .researchBody {
            font-size: large;
            font-weight: 300;
            color: white;
            text-align: center;
            padding: 10px;
        }
    
        .VRBold {
            background: linear-gradient(330deg, var(--magenta) 40%, var(--purple) 60%);
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    
        .kolbBold {
            font-weight: 900;
            background: linear-gradient(330deg, var(--green) 40%, var(--cyan) 60%);
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

* {
  font-family: "Poppins", sans-serif;
  
}
* ::selection {
  color: #F000D3
  
}

.sideVideoContainer {
  backdrop-filter: blur(40px);
  background-color: rgba(200, 200, 200, 0.09);
  width: 70%;
  padding: 30px 40px 30px 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.videoContainer {
    flex: 3
}

.video {
    width: 100%;
    height: 400px;
    border-radius: 10px;
}

.textContainer {
    flex: 1;
}

.text {
    color: #fff;
    text-align: left;
}

@media only screen and (max-width: 720px) {
    .sideVideoContainer {
      flex-direction: column;
    }

    .text {
        text-align: center;
    }
  }
  
.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30;
  background-color: rgba(183, 183, 183, 0.5);
  padding: 10;
  border-radius: 30px;
  width: 200px;
}

.arrowContainer {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;
}

.arrowContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.arrowContainer:active {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sliderText {
  margin-right: 20px;
}

.mainBackground {
  background: linear-gradient(300deg, rgb(0, 3, 71) 10%, rgb(0, 0, 0) 100%),
    linear-gradient(300deg, rgb(255, 255, 255) 120%, rgb(36, 0, 255) 100%),
    linear-gradient(190deg, rgb(0, 68, 170) 0%, rgb(255, 255, 255) 100%),
    radial-gradient(
      100% 200% at 70% 0%,
      rgb(67, 221, 255) 0%,
      rgb(255, 0, 0) 100%
    ),
    linear-gradient(0deg, rgb(219, 0, 255) 0%, rgb(58, 255, 40) 100%),
    radial-gradient(
      200% 100% at 50% 80%,
      rgb(106, 0, 213) 0%,
      rgb(0, 224, 255) 100%
    ),
    radial-gradient(
      100% 150% at 0% 0%,
      rgb(4, 223, 223) 5%,
      rgb(255, 0, 0) 100%
    );
    background-blend-mode: lighten, multiply, overlay, overlay, color-burn,
    color-burn, normal;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.main {
  height: 64vh;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 50px;
  padding-right: 50px;
  gap: 10px;
  padding-bottom: 75px;
}

.missioncontainer {
  display: flex;
  flex-direction: column;
  color: white;
}
.missionHeader {
  font-size: 75px;
  color: white;
}
.missionContent {
  font-size: 25px;
  color: white;
}
.storyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoCard {
  backdrop-filter: blur(40px);
  background-color: rgba(22, 22, 19, 0.2);
  border-radius: 1.63em;
  justify-content: center;
  align-items: center;
  padding: 5px;

  display: flex;
  flex: 1;
  flex-direction: column;
  color: white;
  font-size: .85rem;
}
.cardRow {
  display: flex;
  flex-direction: row-reverse;
  margin: 75px;
}
.boldWord {
  color: #2CF5D3;
}
.imageContainer {
  flex: 1;
  border-radius: 1.63em;
  margin-right: 20px;
  width: 100%;
}

.image {
  width: 90%;
  border-radius: 1.63em;
}

.storyHeader {
  font-size: 60px;
  background: #F60041;
  background: linear-gradient(to right, #F60041 0%, #9f019c 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -100px;
}
.mainTeamContainer {
  background-image: linear-gradient(
    185deg,
    #000131,
    #000131,
    #44003E,
    #000131,
    #000131
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: white;
  font-size: 40px;
}
.teamHeader {
  font-size: 70px;
  background: #121fcf;
  background: linear-gradient(to right, #121fcf 0%, #cf1512 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
}
.footer {
  background: #000131;
}
.teamHeaderContainer {
  background-color: black;
  width: 100%;
}
@media only screen and (max-width: 720px) {
  .missionContainer {
    display: flex;
    flex-direction: column;
    color: white;
  }
  .missionHeader {
    color: white;
    font-size: 40px;
    margin-top: -85px;
  }
  .missionContent {
    font-size: 20px;
    color: white;
  }
  
  .storyHeader {
    margin-top: 4px;
    align-items: center;
    font-size: 3.5rem;
  }
  .cardRow {
    display: flex;
    flex-direction: column;
    margin: 200px;
  }
  .infoCard {
    width: 300px;
    margin-right: 14px;
    margin-bottom: 20px;
    margin-top: -150px;
    font-size: medium;
  }
  .imageContainer {
    display: flex;
    border-radius: 1.63em;
    justify-content: center;
    margin-right: 20px;
    width: 100%;
  }

  .storyText {
    font-size: 3rem;
    align-items: center;
    background: #00faf2;
    background: linear-gradient(to right, #00faf2 14%, #8b15cf 86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .mainTeamContainer {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
  .teamHeader {
    display: flex;
    justify-content: center;
    font-size: 42px;
  }
  .image {
    margin-right: 10px;
    margin-top: 30px;
    position: relative;
  }
  .mainBackground {
    background: linear-gradient(300deg, rgb(0, 3, 71) 10%, rgb(0, 0, 0) 100%),
    linear-gradient(300deg, rgb(255, 255, 255) 120%, rgb(36, 0, 255) 100%),
    linear-gradient(190deg, rgb(0, 68, 170) 0%, rgb(255, 255, 255) 100%),
    radial-gradient(
      100% 200% at 70% 0%,
      rgb(67, 221, 255) 0%,
      rgb(255, 0, 0) 100%
    ),
    linear-gradient(0deg, rgb(219, 0, 255) 0%, rgb(58, 255, 40) 100%),
    radial-gradient(
      200% 100% at 50% 80%,
      rgb(106, 0, 213) 0%,
      rgb(0, 224, 255) 100%
    ),
    radial-gradient(
      100% 150% at 0% 0%,
      rgb(4, 223, 223) 5%,
      rgb(255, 0, 0) 100%
    );
    background-blend-mode: lighten, multiply, overlay, overlay, color-burn, color-burn, normal;
      background-repeat: no-repeat;
      background-size: cover;

}
}
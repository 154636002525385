.avatarVContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 50%;
    margin-bottom: 100px;
    
  }
  .avatarTextContainer {
    width: 50%;
    backdrop-filter: blur(40px);
    background-color: rgba(22, 22, 19, 0.15);
    border-top-left-radius: 1.63rem;
    border-bottom-left-radius: 1.63rem;
    color: white;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .avatarText {
    display: flex;
    font-size: .8rem;
    padding: 20px;
    justify-content: flex-start;
    margin-top: -25px;
  }
  .avatarNameHeader {
    background: #00FAF2;
    background: linear-gradient(to right, #00FAF2 14%, #8B15CF 86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
  
  }
  .avatarImageContainer {
    width: 50%;
    border-top-right-radius: 1.63rem;
    border-bottom-right-radius: 1.63rem;
    overflow: hidden;
  }
  
  .avatarImage {
    width: 80%;

  }
  @media only screen and (max-width: 720px) {
    .avatarVContainer {
      display: flex;
      flex-direction: column !important;
      width: 80% !important;
      align-items: center;
      
    }
    .avatarImageContainer {
      width: 80%;
      display: flex;
      justify-content: center;

      
    }
    .avatarTextContainer {
      width: 100%;
      color: white;
      text-align: center;
      background-color: rgba(22, 22, 19, 0.12);
      text-align: center;
    }
    .avatarImage {
      width: 80% !important;
      border-radius: 1.63rem;
    }
    .avatarText {
      color: white;
    }
  }
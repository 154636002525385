.HContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 40px;
}

.textContainer {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  color: #fff;
  width: 50%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  width: 50%;
  border-radius: 20px;
  overflow: hidden;
}

.textMargin {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 1.3rem;
}

video::-webkit-media-controls {
  display: none;
}

@media only screen and (max-width: 720px) {
  .textContainer {
    width: 100%;
    min-height: 400px;
  }

  .imgContainer {
    width: 100%;
  }
}

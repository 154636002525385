.socialMediaButton {
    border-radius: 15px; 
    border: none; 
    height: 50; 
    color: #000;
    background-color: #B9F318;
    transition: 0.5s;
}

.socialMediaButton:hover {
    background-color: #a5d61d;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.socialMediaButton:active {
    box-shadow: none;
}
/* Main Navbar */
.mainNavContainer {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 999;
  -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.removeDefaultStyle {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background-color: rgba(0, 0, 0, 0);
  margin: 0px;
  margin-left: 20px;
}

.navList {
  height: 100%;
  padding: 10px 25px;
  transition: all 0.5s ease;
  text-decoration: none;
  border-radius: 35px;
}

.activeLink {
  border-bottom: 2px solid #b9f318;
  width: 50%;
}

.activeLink:hover {
  border-bottom: 0px solid #B9F318;
  color: #000;
}

.logoImg {
  width: 300px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.navList:hover {
  background-color: #b9f318;
  color: #000;
  scale: 1.15;
}

.navList:active {
  background-color: #b9f318;
}

/* Mobile Nav Bar */
.burgerButton {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(1px);
  color: #fff;
  margin-left: 15px;
  border-radius: 40px;
  border: none;
  font-size: 20px;
  padding: 15px 20px;
  transition: all 0.5s ease;
}

.burgerMenuUl {
  margin-top: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  position: absolute;
  width: 87%;
  border-radius: 15px;
  list-style: none;
  z-index: 999;
}

.burgerButton:hover {
  background-color: #60f42d;
  color: #000;
}

@media only screen and (max-width: 720px) {
  .mainNavContainer {
    padding: 10px 0px;
  }
  .removeDefaultStyle {
    display: none;
  }

  .removeDefaultStyle,
  .mainNavContainer {
    background-color: rgba(255, 255, 255, 0);
  }

  .logoImg {
    width: 150px;
    /* background-color: rgba(0, 0, 0, 1); */
    padding: 5px;
    border-radius: 10px;
    margin-right: 15px;
  }
  .navList {
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}


@media only screen and (min-width: 720px) {
  .mobileNavBarContainer {
    display: none;
  }
}
